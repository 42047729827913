<template>
  <div class="richtext" v-html="content" />
</template>

<script lang="ts" setup>
import type Richtext from 'storyblok-js-client'
import { parseHtml, stringifyHtml } from '@/utils/node-dom'

import { slugify } from '@/utils/helpers'
const props = defineProps<{
  richTextTemplate: Richtext
}>()
const { decodeAndReplaceText } = useTextModifiers()

const encodedRichText = computed(() => {
  return (
    props.richTextTemplate &&
    JSON.parse(decodeAndReplaceText(JSON.stringify(props.richTextTemplate)))
  )
})

const addIdToHeadings = (html: string) => {
  const root = parseHtml(html)
  const headings = root.querySelectorAll('h2, h3')
  headings.forEach((heading) => {
    const id = slugify(heading.text)
    heading.setAttribute('id', id)
  })
  return stringifyHtml(root)
}

const content = computed(() => {
  return addIdToHeadings(renderRichText(encodedRichText.value))
})
</script>
